import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import dateInEnglish from "../Utils/dateInEnglish"
import truncateText from "../Utils/truncateText"

import MyRenderer from "../Utils/myrenderer"
import "./posts.scss"

function getFirstTwoSentences(text) {
	const regex = /(?:^|\n)[^\n]*?[.!?](?:\s|$)(?:[^\n]*?[.!?](?:\s|$))?/s;
	const match = regex.exec(text);
	return match && match[0] ? match[0].trim() : '';
  }
export default function Posts({ data }) {
	const allMdx = usePostQuery()

	const [descriptions, setDescriptions] = useState({});

	useEffect(() => {
	  const loadDescriptions = async () => {
		const loadedDescriptions = {};
  
		for (const post of allMdx.edges) {
		  const descriptionPath = `../../content/introductions/${post.node.slug}.mdx`;
  
		  try {
			const DescriptionComponent = await import(descriptionPath);
			loadedDescriptions[post.node.slug] = DescriptionComponent.default;
		  } catch (error) {
			console.error(`Failed to load description for ${post.node.slug}:`, error);
		  }
		}
  
		setDescriptions(loadedDescriptions);
	  };
  
	  loadDescriptions();
	}, [allMdx]);

	if (!allMdx)
		return (
			<h2 style={{ textAlign: "center", marginTop: "50px", color: "gray" }}>
				No posts found.
			</h2>
		)
	return (
		<>
			<section className="Posts">
				<h2 className="Posts__banner">Posts</h2>
				{allMdx.edges.filter((post) => !post.node.frontmatter.hidden).map((post, i) => {
					const descriptionPath = `../../content/introductions/${post.node.slug}.mdx`;

				
					console.log(`Resolved path for ${post.node.slug}:`, descriptionPath);
					return (
					<div className="Post" key={i}>
						<Link to={`/posts/${post.node.slug}`} className="Post__metainfo">
							<h3 className="Post__title">{post.node.frontmatter.title}</h3>
							<h5 className="Post__date">
								{dateInEnglish(post.node.frontmatter.date)}
							</h5>
						</Link>
						<div className="Post__description">
							{/* <MyRenderer>{getFirstTwoSentences(post.node.body)}</MyRenderer> */}
							{/* <MyRenderer>{getFirstTwoSentences(post.node.rawBody)}</MyRenderer> */}
							{/* <MyRenderer>{post.node.firstTwoSentences}</MyRenderer> */}
							{/* {getFirstTwoSentences(post.node.rawBody)} */}
							<MyRenderer>{post.node.body}</MyRenderer>
						</div>
						<Link to={`/posts/${post.node.slug}`} className="Post__readmore">
							Read more
						</Link>
					</div>
				)})}
			</section>
		</>
	)
}

export const usePostQuery = () => {
	// Fetch latest posts
	const { allMdx } = useStaticQuery(
		graphql`
			query FetchRecentPosts {
				allMdx(
					sort: { order: DESC, fields: frontmatter___date }
					filter: { fileAbsolutePath: { regex: "/content/introductions/" } }
				) {
					edges {
						node {
							excerpt
							body
							frontmatter {
								date
								description
								title
								hidden
							}
							slug
							rawBody
						}
					}
				}
			}
		`
	)
	return allMdx
}
